import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';

// const API_USERS_URL = `${environment.apiUrl}/auth`;
const API_USERS_URL = `https://crewapi.maritimecloud.ai/api`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  // login(user: string, password: string): Observable<any> {
  //   return this.http.post<any>(`${API_USERS_URL}`, {
  //     user,
  //     password,
  //   });
  // }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/auth/login`, {
      username,
      password,
    });
  }

  logout(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/logout`);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    // return this.http.get<any>(`${environment.apiUrl}/userinfo`, {
    //   headers: httpHeaders,
    // });
    return this.http.get<any>(`${API_USERS_URL}/userinfo`, {
      headers: httpHeaders,
    });
  }
}
